/*
Theme Name: AW-Stark Template 1.0
Description: AW-Stark Template 1.0
Version: 1.0
Author: Shapefruit AG - Christian Lersch
Author URI: http://shapefruit.de
License: GPL
*/

/* AUTOREN */

/* !!!!!!!!!!!!!!!!!!!!!! RPT VERSION !!!!!!!!!!!!!!!!!!!!!!  */

/* -----------------------------------------
   GLOBALE STYLES
----------------------------------------- */

@font-face {
  font-family: 'Open Sans';
  src: url('https://cbg-gmbh.com/wp-content/themes/cbg/css/fonts/OpenSans-Regular-400.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('https://cbg-gmbh.com/wp-content/themes/cbg/css/fonts/OpenSans-Bold-700.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('https://cbg-gmbh.com/wp-content/themes/cbg/css/fonts/OpenSans-SemiBold-600.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('https://cbg-gmbh.com/wp-content/themes/cbg/css/fonts/OpenSans-Light-300.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

html body{
  font-size: 16px;
  font-family: $font-family-base;
  color: $secondary;
  line-height: 1.6em;
  font-weight: 400;
}

.header-spacer{
padding-top: 200px;
}

h1{
  color: $primary;
  font-weight: 600;
  font-size: 40*$px-base;
}

h2{
  color: $primary;
  font-weight: 600;
  font-size: 34*$px-base;
}

h3{
  color: $secondary;
  font-weight: 600;
  font-size: 25*$px-base;
}

h4{
  font-size: 18px;
}

hr{
  width: 100px;
  border: none;
  border-bottom: solid 1px $primary;
}

blockquote {
  position: relative;
  padding: 30px;
  /* background: #ddd; */
}

blockquote:before {
  position: absolute;
  content: open-quote;
  font-size: 4em;
  margin-left: -0.6em;
  color: #e5702a;
  font-style: italic;
}
blockquote:after {
  position: absolute;
  content: close-quote;
  font-size: 4em;
  bottom: 0;
  right: 30px;
  color: #e5702a;
  font-style: italic;
}
blockquote p {
  display: inline;
}

.nichtmobilanzeigen{
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.container, .container-fluid{

}

.container.withoverflow{
  overflow: visible;
}

.container.headerbar{
  overflow: visible;
}

.row .container{
  margin: auto;
}

.vc-hoverbox-inner {
  min-height:450px!important;
}

.vc_btn3.vc_btn3-size-lg .vc_btn3-icon{
  font-size: 30px !important;
}

.container-fluit .container{
  margin: auto;
}

.weisseschrift *{
  color: #ffffff !important;
}

.tuerkis{
  color: $secondary;
}

.orange{
  color: $primary;
}

.white{
  color: #ffffff;
}

.container.vc_col-sm-12{
  float: none;
}

.vc_row.container{
  margin: auto;
}

img.alignleft{
  float:left;
  margin-right:10px;
}

.right{
  text-align: right;
}

.onmobiletotheleft {
  order: -1;
  margin-top: 40px !important;
}
html body .vc_btn3.vc_btn3-color-orange,
html body .vc_btn3.vc_btn3-color-orange.vc_btn3-style-flat{
  background-color: $primary;
}

html body .vc_btn3.vc_btn3-color-grey,
html body .vc_btn3.vc_btn3-color-grey.vc_btn3-style-flat{
  background-color: $quatiary;
  color: #ffffff;
}

@include media-breakpoint-up(md) {
  .onmobiletotheleft {
    order: 1;
  }
}