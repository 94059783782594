.nf-form-cont{
  .nf-form-fields-required, nf-form-fields-required *{
    color: #969696;
  }
}

.nf-form-content{
  input, textarea{
    border: none !important;
  }

  hr{
    border: none;
    border-bottom: solid 1px #969696;
    margin: 30px 0px !important;
  }

  h2{
    text-transform: uppercase;
    color: #969696;
    font-size: 22px;
    font-weight: bold;
  }

  h3{
    text-transform: uppercase;
    color: #969696;
    font-weight: bold;
  }

  .nf-field-label label{
    line-height: 1.6rem !important;
    font-weight: 600;
    color: #969696;

    .ninja-forms-req-symbol{
      color: #969696;
    }
  }

  .nf-field-description{
    font-size: inherit;
  }

  .nf-field-container{
    padding: 0px 60px;
  }

  .hr-container{
    padding: 0px;
  }

  .listcheckbox-wrap label{
    line-height: 1.6rem;
  }

  .checkbox-wrap{
    flex-direction: column !important;
  }

  .btn, .submit-container input{
    background-color: $primary !important;
    border-radius: 5px;

  }
  .submit-container .nf-field-element{
    text-align: center;
  }
  .nf-breadcrumbs {
    li a{
      background-color: #A2A2A23E;
      color: #495057;
    }
    li a:hover{
      background-color: $primary;
      color: #ffffff;
    }
    li.active a{
      background-color: $primary;
      color: #ffffff;
    }
  }
  .nf-next-previous{
    li input{
      background-color: #A2A2A23E;
      color: #495057;
    }
    li input:hover{
      background-color: $primary;
      color: #ffffff;
    }
  }
}

.nf-form-fields-required{
  text-align: center;
  padding-bottom: 20px;
}

html body .nf-form-content button:hover,
html body .nf-form-content input[type=button]:hover,
html body .nf-form-content input[type=submit]:hover{
  color: #000000;
  transition: none;
}