.hintergrundpattern01{
  background-image: url('images/elemente/background-pattern-01.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
}

.hintergrundpattern02{
  background-image: url('images/elemente/background-pattern-02.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}

.topbottomborderbox{
  border-bottom: solid 1px #000000;
  border-top: solid 1px #000000;
  padding: 30px 0px;
}

#tabsoben .vc_tta-tabs{
  .vc_tta-tabs-container .vc_tta-tabs-list{
      display: flex !important;
      align-content: stretch;
      li{
        width: 100%;
        a{
          background-color: transparent !important;
        }
        a:hover{
          border-bottom: solid 1px #f59c05 !important;
        }
      }
    li.vc_active{
      a{
        border-bottom: solid 1px #015daa !important;
        font-weight: bold;
      }
    }
  }
}

.vc_row-has-fill *{
  color: #ffffff;
}

.wpb-js-composer #tabs-liste{
  .vc_tta-tabs{
    flex-direction: row-reverse;
  }
  .vc_tta-tabs-container{
    width: 50%;
  }
  .vc_tta-tab>a{
    background-color: #ffffff;
    color: $primary;
    margin-left: 45px;
    border-radius: 0px;
  }
  .vc_tta-tab.vc_active>a{
    background-color: $secondary;
    color: #ffffff;
    height: 65px;
    transition-duration: 0s;

    &::after {
      color: $secondary;
      border-right: 32px solid;
      border-top: 32px solid transparent;
      border-bottom: 32px solid transparent;
      display: inline-block;
      content: '';
      position: absolute;
      left: -38px;
      height: 65px;
      top: -3px;
    }
  }
}

.newsbox{
  border-top: solid 1px #000000;
  border-bottom: solid 1px #000000;
  margin-right: 10px;
  padding-top: 30px;

  .wpb_content_element{
    margin-bottom: 15px;
  }

  .vc_btn3.vc_btn3-color-blue{
    background-color: $primary !important;
  }
  .vc_btn3.vc_btn3-color-blue:hover{
    background-color: $secondary !important;
  }
}

html body .vc_btn3.vc_btn3-color-primary, .vc_btn3.vc_btn3-color-primary.vc_btn3-style-flat{
  background-color: $primary !important;
}

.vc-gitem-zone-height-mode-auto.vc-gitem-zone-height-mode-auto-16-9:before{
  @include media-breakpoint-down(xs) {
    padding-top: 0px !important;
  }
}

.vc_gitem-zone-a .vc_gitem-row-position-middle, .vc_gitem-zone-b .vc_gitem-row-position-middle{
  @include media-breakpoint-down(xs) {
    position: relative !important;
    top: 0 !important;
    transform: none !important;
  }
}

.p21logo img{
  width: 60px !important;
  height: 80px !important;
}

.wpgmza_infowindow_image {
  width: 150px !important;
}

.flexslider, .flex-viewport{
  height: 500px !important;
}

//ACCORDION STYLEN
html body .vc_tta-panels-container .vc_tta-panels .vc_tta-panel{
  .vc_tta-panel-body, .vc_tta-panel-heading {
    background-color: #ffffff !important;
    border-color: #ffffff !important;
  }
  .vc_tta-panel-title {
    background-position: center;
    background-repeat: repeat-x;
    font-family: $headings-special-font-family;
    font-size: 25px;

    a {
      border: solid 20px #ffffff;
      color: #000000 !important;
      padding: 0px;
      .vc_tta-title-text, .vc_tta-controls-icon{
        background-color: #ffffff;
      }
      .vc_tta-controls-icon{
        right: 0px !important;
      }
    }
  }
}
html body.tuerkisworld .vc_tta-panels-container .vc_tta-panels .vc_tta-panel .vc_tta-panel-title {
  border-top: solid 1px $secondary;
}

//SLICK STYLE
.slick-slider{
  .slick-arrow{
    height: 35px;
    width: 20px;
    background-size: cover;
    &.slick-next{
      background-image: url('images/icons/pfeil-right.svg');
    }
    &.slick-prev{
      background-image: url('images/icons/pfeil-left.svg');
      left: -35px;
    }
    &::before{
      content: '';
    }
  }
}
html body #o-wrapper .slick-slider{
  min-height: 410px;
  margin-right: 0px !important;

  .slick-next{
    right: 0px;
  }

  .slick-slide {
    margin: 10px;
    width: 33vw;
    min-height: 500px;
    padding: 0px;
    margin: 0px;
    float: left !important;

    &>div{
      width: 90%;
    }
    .vc_gitem_row{
      background-color: #ffffff;
      margin: 0px 10px;
      min-height: 420px;
    }

    *{
      overflow: visible;
      color: #000000;
      float: none;
      clear: both;
    }
    .wpb_single_image{
      margin-bottom: 5px;
    }
    .vc_gitem-post-data-source-post_date *{
      font-size: 12px;
      color: #969696;
    }
    .vc_gitem-post-data-source-post_title{
      font-weight: bold;
    }
  }
}

.makemeslide .wpb_image_grid_ul{
  height: inherit !important;
  min-height: inherit !important;

  li{
    position: relative !important;
    top: inherit !important;
    left: inherit !important;
    min-height: inherit !important;
  }
  .slick-list{
   margin: 0px 30px;
  }
  .slick-arrow {
    &.slick-next {
      right: 0px;
    }

    &.slick-prev {
      left: 0px;
    }
  }
}

.hatslider .vc_row{
  overflow: visible;
}
#kopfbild.kopfbild{
  background-image: url('images/header.png');
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 100%;
  padding-top: 200px;
  @include media-breakpoint-down(md) {
    background-color: $primary;
    background-image: none;
  }
  @include media-breakpoint-down(xs) {
    background-color: $primary;
    background-image: none;
    padding-top: 30px;
  }
  @include media-breakpoint-up(xl) {
    background-size: 150%;
  }
}

@media (min-width: 1400px){
  #kopfbild.kopfbild .slick{
    height: 800px;
  }
}
#kopfbild.kopfbild .slick{
  padding-left: 0px;

  .slick-list{
    padding-top: 40px;
    padding-bottom: 100px;
    overflow: hidden;

    .slick-slide{
      margin: 10px;
      width: 100vw;
      min-height: 410px;
      background-color: transparent;
      padding: 10px 100px;
      z-index: 100;
      position: relative;

      img{
        padding-bottom: 10px;
        width: 100%;
      }

      &>div{
        width: 100% !important;
      }

      .slidecontent{
        .text{
          *{
            color: #ffffff;
          }
          padding: 50px;
          @include media-breakpoint-down(md) {
            padding:15px;
          }
          @include media-breakpoint-down(xs) {
            .exerpt{
              display: none;
            }
          }
        }
      }

      h3{
        font-size: 30px;
        color: #ffffff;
        font-weight: 600;
        @include media-breakpoint-down(md) {
          font-size: 20px;
        }

        b, strong{
          color: $quatiary !important;
        }
      }

      blockquote{
        font-size: 26px;
        line-height: 36px;
        font-weight: 300;
      }

      hr{
        width: 35px;
        border: none;
        border-bottom: 1px solid $secondary;
      }

      .vc_btn3.vc_btn3-color-orange.vc_btn3-style-flat{
        margin-top: 30px;
        background-color: $secondary;
        color: #ffffff;
        &:hover{
          color: #000000;
        }
      }

      &.slick-current{
        background-color: transparent;
        z-index: 150;
      }
    }
  }
  .slick-arrow{
    font-size: 60px;
    top: 45%;
  }
  .slick-prev{
    left: 25px;
  }
  .slick-next{
    right: calc(1vw + 30px);
    color: #000000;
    &:hover, &:active{
     color: $primary;
    }
    &::before{
      content: '';
    }
  }
}

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus{
  background-size: cover !important;
}

.center{
  text-align: center;
}

.vc_tta-container{
  .orange{
    color: #000000;
    strong{
      color: $primary;
    }
  }
  .tuerkis{
    color: #000000;
    strong{
      color: $secondary;
    }
  }
}

.orangeworld{
  h4, h5{
    color: $primary;
  }
}

.tuerkisworld{
  h4, h5{
    color: $secondary;
  }
}

.zeitstrahl{
  .zeitstrahlbox{
    border-bottom: solid 1px $primary;
    transform: scale(0.9);
    transition: transform 0.5s;

    .vc_column-inner{
      min-height: 250px;
    }

    &:hover{
      transform: scale(1);
    }

    @include media-breakpoint-down(xs) {
      padding: 30px;
    }
  }
}

.tuerkisworld .zeitstrahl .zeitstrahlbox{
  border-bottom: solid 1px $secondary;
}

html body .gapyeartabs{
  background-color: #ffffff;
  padding: 40px;

  .vc_tta-panel-title{
    border: none !important;
  }
  .vc_tta-tab{
    a{
      border: none !important;
      background-color: transparent !important;
    }
  }
  .vc_tta-tabs-list{
    .orange{
      &.vc_tta-tab{
        a{
          color: $primary !important;
          font-weight: bold;
        }
      }
      &.vc_active {
        border-bottom: solid 1px $primary !important;
      }
    }
    .tuerkis{
      &.vc_tta-tab{
        a{
          color: $secondary !important;
          font-weight: bold;
        }
      }
      &.vc_active{
        border-bottom: solid 1px $secondary !important;
      }
    }
  }
  .vc_tta-panel{
    &.orange{
      .vc_tta-panel-heading{
        a{
          color: $primary !important;
          font-weight: bold;
        }
      }
      &.vc_active  {
        .vc_tta-panel-heading {
          a {
            border-bottom: solid 1px $primary !important;
          }
        }
      }
    }
    &.tuerkis{
      .vc_tta-panel-heading{
        a{
          color: $secondary !important;
          font-weight: bold;
        }
      }
      &.vc_active  {
        .vc_tta-panel-heading {
          a {
            border-bottom: solid 1px $secondary !important;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .vc_tta-panel-body{
      display: block !important;
    }
    .orange .vc_tta-panel-title a{
      border-bottom: solid 1px $primary !important
    }
  }

}

html body .vc_btn3.vc_btn3-color-orange.vc_btn3-style-flat:focus, html body .vc_btn3.vc_btn3-color-orange.vc_btn3-style-flat:hover, html body .vc_btn3.vc_btn3-color-orange:focus, html body .vc_btn3.vc_btn3-color-orange:hover{
  background-color: $primary;
}

html body .vc_btn3.vc_btn3-color-turquoise, html body .vc_btn3.vc_btn3-color-turquoise.vc_btn3-style-flat{
  background-color: $secondary;
  &:hover{
    background-color: $secondary;
    color: #000000;
  }
}

.wpb_text_column{
  margin-bottom: 10px !important;
}
.vc_separator{
  margin-bottom: 10px !important;
}

html body .vc_separator-has-text{
  h4{
    text-align: center;
    font-family: $headings-special-font-family;
    color: #000000;
    font-size: 26px;
    line-height: 32px;
    margin: 0px 20px;
  }
}
.vc_separator.vc_sep_color_orange .vc_sep_line{
  border-color: $primary !important;
  margin-bottom: 0px;
}

.vc_separator.vc_sep_color_turquoise .vc_sep_line{
  border-color: $secondary !important;
}

@include media-breakpoint-up(lg) {
  .hoverme {
    transform: scale(0.9);
    transition: transform 0.5s;
  }

  .hoverme:hover {
    transform: scale(1);
  }
}

.vc_col-has-fill{
  .vc_column-inner {
    background-position: center;
  }
}

.post-password-form{
  width: 500px;
  margin: auto;
  padding-top: 150px;
  padding-bottom: 150px;
  font-weight: bold;
  text-align: center;
  @include media-breakpoint-down(xs) {
    width: 300px;
  }
}

.startseite_aktuelles_box{
  -webkit-box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.16);
  -moz-box-shadow:    0px 2px 15px rgba(0, 0, 0, 0.16);
  box-shadow:         0px 2px 15px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  min-height: 450px !important;

  .vc_gitem_row{
    position: relative !important;
    top: inherit !important;
    transform: none !important;
    margin: 0px !important;
  }
  .vc_gitem-col{
    padding: 0px !important;

    .vc_custom_heading{
      padding: 0px 20px;
    }

    .vc_btn3-container{
      margin-right: 30px;
    }
    .vc_gitem-post-data-source-post_title, .vc_gitem-post-data-source-post_title *{
      font-weight: bold !important;
      color: $secondary !important;
      font-size: 21px !important;
    }
  }
}

.shariff{
  *{
    color: $secondary !important;
  }
  margin: 50px 0px;
  margin-bottom: 250px;

  .ShariffHeadline{
    text-align: center;
  }
}

.besondereaufzaehlung{
  ul{
    padding-left: 20px;
  }
  ul li{
    list-style: none;
    line-height: 30px;
    list-style-position: outside;
  }
  ul li::before{
      content: '';
      display: inline-block;
      height: 20px;
      width: 20px;
      background-image: url('images/icons/liststyleicon.svg');
      background-position: center;
      background-repeat: no-repeat;
    padding-left: 35px;
    margin-left: -35px;
  }
}