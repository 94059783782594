.page-template-page-ohnekopfbild{
  .header{
    position: relative;
  }
  .headerbar .mobilmenubutton .navbar-toggler #menubutton{
    fill: $tertiary;
  }
}

.blacklinks a{
  color: #000000;
}