.breadcrumbs{
  padding: 20px 0px;
  text-align: left;
  a{
    color: #000000;
    font-weight: normal;
  }
  .current-item{
    color: $primary;
    font-weight: normal;
  }
  .seperator{
    padding: 0px 10px;
    i{
      font-size: 20*$px-base;
    }
  }
}