.header{
  z-index: 100;
  overflow: visible;
  padding: 0 !important;
  display: flex;
  justify-content: space-between;
  @include media-breakpoint-down(sm) {
    position: relative;
    flex-direction: column;
    align-items: center;
  }
}

body.single .header, body.page-template-page-unternehmendetailseite .header{
  -webkit-box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.16);
  -moz-box-shadow:    0px 2px 20px rgba(0, 0, 0, 0.16);
  box-shadow:         0px 2px 20px rgba(0, 0, 0, 0.16);
}

.headerbar{
  z-index:200;
  padding: 0px;
  min-height: 150px;
  background-color: #ffffff;

  .logo_eu{
    padding-left: 20px;
    width: 50px;
    @include media-breakpoint-up(md) {
      width:78px;
    }
  }
  .logo{
    display: flex;
    padding: 25px;
    img{
      width: 350px;
      @include media-breakpoint-down(md) {
        width: 200px;
      }
    }
  }
}
.header.sticky{
  position: fixed;
  background-color: #ffffff;
  height: 100px;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
  *{
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -ms-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
  }
  .headerbar{
    min-height: 80px;
    @include media-breakpoint-down(md) {
      min-height: 100px;
    }
    .logo{
      padding: 5px;
      img{
        width: auto !important;
        height: 90px !important;
      }
    }
  }
}
.navigation {
  display: flex;
  padding: 35px;
  background-color: $primary;
  height: 100px;
  align-items: center;
  @include media-breakpoint-down(md) {
    padding: 10px;
  }

  .menu{
    display: flex;
    list-style: none;
    margin: 0px;
    padding: 0px;
    @include media-breakpoint-down(md) {
      align-items: center;
    }

    a{
      color: #ffffff;
      font-weight: normal;
      line-height: 1.2em;
      padding: 10px;
      padding-top: 20px;
      @include media-breakpoint-down(md) {
        padding: 5px;
      }
    }
    .seperator{
      color: #ffffff;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
    li{
      text-align: center;
      &:last-of-type{
        .seperator{
          display: none;
        }
      }
    }
  }

  .searchbutton {
    font-size: 1.6em;
    background-color: transparent;
    border: none;
    widht: 50px;
  }
  @include media-breakpoint-down(md) {
    a{
      font-size: 12px;
    }
  }
}

img.suchbutton{
  width: 50px;
  @include media-breakpoint-down(md) {
    width: 35px;
  }
}

html body .shiftnav{
  background-color: #f59c05;

  li{
    border-bottom: solid 1px #ffffff;
  }
  ul.shiftnav-menu li.shiftnav-depth-2{
    background-color: #f7b037
  }
  ul.shiftnav-menu li.shiftnav-depth-3{
    background-color: #f9c469
  }
  a{
    color: #ffffff !important;
  }
}

.headerbereich{

}

.c-mask{
  display: none;
}

.kontaktnavbutton{
  color: #ffffff;
}

.hauptnavigationmobil{
  .c-menu{
    background-color: $secondary;

    .c-menu__close{
      background-color: $primary;
    }
    ul{
      list-style: none;
      padding: 0;
      li{
        display: block;
        text-align: center;
        border-bottom: solid 1px #ffffff;
        a{
          display: block;
          padding: 15px;
          color: #ffffff;
        }
        ul.sub-menu{
          background-color: $secondary;
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .navigation .ubermenu  li.ubermenu-item-level-0{
    width: 80px;
    .ubermenu-target-title{
      font-size: 12px;
      line-height: 17px;
    }
  }
  .navigation .ubermenu-responsive-default.ubermenu {
    display: none !important;
  }
}

@media only screen and (min-width: 1200px ) {
  #shiftnav-toggle-main{
    display: none;
  }
}