.postgridview{
  .card{
    -webkit-box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.16);
    -moz-box-shadow:    0px 5px 11px rgba(0, 0, 0, 0.16);
    box-shadow:         0px 5px 11px rgba(0, 0, 0, 0.16);

    .wp-post-image{
      width: 100% !important;
      height: auto;
    }

    .card-body{
      padding: 10px;
      @include media-breakpoint-down(xs) {
        padding: 20px;
      }
      .card-text .text-muted{
        color: $primary !important;
        text-transform: uppercase;
      }
      .card-title a{
        color: #000000;
        text-transform: none;
        line-height: 1.5em;
      }
      hr{
        border: none;
        border-bottom: 2px solid $primary;
        width: 30px;
      }
    }
    .card-footer{
      padding: 10px;
      background-color: transparent;

      .btn{
        color: #ffffff;
        padding: 10px;
        border-radius: 5px;
        &:hover{
          color: #000000;
        }
      }
    }
  }
  .shadowbox{
    display: flex;
  }
  @include media-breakpoint-up(md) {
    .shadowbox:nth-child(2n){
      position: relative;
      top: 50px;
    }
  }
}

.beitragspagenavi{
  div{
    text-align: center;
    a{
      font-weight: bold;
    }
  }
}

.search {
  .searchtop{
    h1{
      color: $primary;
    }
    hr{
      border: none;
      border-bottom: solid 1px $primary;
      padding: 20px 0px;
    }
    .searchform {
      label{
        font-weight: bold;
      }
      input{
        border: none;
        background-color: #f7f7f7;
        padding: 10px;
      }
      input[type=submit]{
        background-color: $primary;
        color: #ffffff;
      }
    }
  }
  .postgridview {
    .shadowbox:nth-child(2n) {
      top: inherit;
    }
    .card{
      width: 100%;
    }
  }
}
.orangeworld .postgridview .card .card-footer .btn:hover{
  background-color: $primary;
}

.tuerkisworld .postgridview .card .card-footer .btn:hover{
  background-color: $secondary;
}
