.footer{
  background-color: #e5702a;
  position: relative;
  .abgeschraegtesdach{
    background-color: #ffffff;
    width: 100%;
    height: 300px;
    transform: skewY(-6deg);
    z-index: 100;
    position: absolute;
    top: -120px;
  }

  .container{
    z-index: 200;
    position: relative;
  }

  *{
    color: #ffffff;
  }

  h4{
    color: #ffffff;
    text-align: left;
    font-size: 25px;
    width: 100%;
  }

  strong{
    color: $quatiary;
    font-weight: normal;
  }

  .wpb_column{
    @include media-breakpoint-down(md) {
      padding: 20px;
    }
  }

  .container > .row > div > .vc_row > .wpb_column{
    border-right: solid 1px #ffffff;
  }

  .footersvg{
    width: 40px;
    height: 40px;
    margin:auto;
    margin-bottom: 25px;
  }

  .nf-form-cont{
    .nf-form-fields-required{
      display: none;
    }
    .nf-form-content {
      .nf-field-container{
        padding: 0px;
      }
      .submit-container input[type=button]{
        background-color: $quatiary !important;
      }
      .submit-container .nf-field-element{
        text-align: left;
      }
    }
  }

  .footernav{
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 50px;
    padding-top: 40px;
    ul{
      list-style: none;
      margin: 0px;
      padding: 0px;
      display: flex;
      justify-content: left;
      li{
        a{
          padding: 0px 20px;
          color: #ffffff;
          text-transform: uppercase;
          font-size: 18px;
        }

          .seperator{
            display: none;
          }

      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .swf_logo {
    flex-direction: column;
    align-items: center;

    .seperator {
      display: none;
    }
  }

  .footernav ul {
    flex-direction: column;
    align-items: center;

    li {
      margin: 5px 0px !important;
    }
    .seperator {
      display: none;
    }
  }
}