.startseitencontent .kopfbild{
  min-height: 520px;

  @include media-breakpoint-down(xs) {
    min-height: 390px;
    height: 430px;
  }
  .vc_row-has-fill{
    min-height: 830px;
    @include media-breakpoint-down(md) {
      min-height: 430px;
    }
  }
}

.tuerkisworld .kopfbild .kopfbildrahmenoben{
  background-image: url('images/icons/kopfbildrahmen_oben_tuerkis.svg') !important;
}

.tuerkisworld .kopfbild .kopfbildrahmenunten{
  background-image: url('images/icons/kopfbildrahmen_unten_tuerkis.svg') !important;
}

.orangeworld .kopfbild .kopfbildrahmenoben{
  background-image: url('images/icons/kopfbildrahmen_oben.svg') !important;
}

.orangeworld .kopfbild .kopfbildrahmenunten{
  background-image: url('images/icons/kopfbildrahmen_unten.svg') !important;
}

.kopfbild .kopfbildrahmenoben.orange{
  background-image: url('images/icons/kopfbildrahmen_oben.svg') !important;
}

.kopfbild .kopfbildrahmenunten.orange{
  background-image: url('images/icons/kopfbildrahmen_unten.svg') !important;
}

.kopfbild{
  min-height: 530px;
  position: relative;
  overflow: hidden;

  @include media-breakpoint-down(md) {
    min-height: 330px;
    height: 330px;
  }

  h1{
    color: #000000;
    font-size: 65px;
    line-height: 1em;
    @include media-breakpoint-down(md) {
      font-size: 35px;
    }
  }

  .kopfbildrahmenoben{
    height: 76px;
    position: absolute;
    top: -1px;
    left: 0px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
  }

  .kopfbildrahmenunten{
    height: 64px;
    position: absolute;
    bottom: -1px;
    left: 0px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
  }

  .vc_row-has-fill{
    min-height: 530px;
    background-position: center;
    background-size: cover;
    z-index: 50;
    @include media-breakpoint-down(md) {
      min-height: 330px;
    }
  }
}

.startseitencontent{
  z-index: 95;

  .maincontentbox{
    position: relative;
    max-width: 500px;
    padding: 20px;
    margin: 20px;
    margin-top: 200px;
    border-top: solid 1px #000000;
    border-bottom: solid 1px #000000;
    @include media-breakpoint-down(md) {
      margin-top: 30px;
    }
  }
  .startseitenkarte{
    clear: both;

    h2{
      text-align: center;
      padding-top: 100px;
      padding-bottom: 30px;
    }
  }

}