.postthumbnail{
  position: relative;
  img{
    object-fit: cover;
    height: 300px;
    @include media-breakpoint-up(md) {
      width: 100%;
      height: auto;
    }
  }
  .postthumbnailtext{
    text-align: center;
    font-style: italic;
    font-size: 14px;
  }
  h1{
    position: absolute;
    top: 150px;
    right: 100px;
    color: #ffffff;
    font-weight: normal;
    @include media-breakpoint-up(xl) {
      font-size: 75*$px-base;
    }
    @include media-breakpoint-down(md) {
      font-size: 30*$px-base;
      right: 50px;
    }
  }
}

.postheadline{
  padding:30px 0px;
}

.postbody{
  position: relative;
  z-index: 200;
  @include media-breakpoint-down(md) {
    padding: 0px 20px;
  }
}

.spacer{
  padding: 30px 0px;
}